header{
    position:fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 2;
    padding: 20px 0 ;
    background-color: #ffffff41;
}

.header-case{
    display: flex;
    max-width: 1160px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.header-nav{
    display: flex;
    justify-content: space-between;
    min-width: 20%;
    
    &>a{
        color: #333333;
        font-weight: 600;
        text-decoration: none;

        &:hover{
            
        }
    }
}