body{
    margin: 0;
    padding:0;
}

span,a,h1,h2,h3,h4,h5,button{
    font-family: 'Open Sans', sans-serif;
}

h1{
    font-size: 38px;
    font-weight: 700;
}

span{
    font-size: 18px;
    color: #333;
}