.intro-content{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &_case{
        display: flex;
        flex-direction: column;
        max-width: 1360px;
        width: 100%;
        position: absolute;
        top: 60%;
        margin: 0 auto;

        
    }

    &_info{
        max-width: 560px;

        &>h1{
            font-size: 48px;
            color: #ffffff;
        }

        &>span{
            color: #ffffff;
        }
    }
}
